<template lang="html">
    <div>
         <b-navbar type="light" class="nav-bg" fixed="top">
            <img src="../../assets/img/logo.png" class="rounded mx-auto d-block nav-img" >
        </b-navbar>
        <b-container class="content-section">
            <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
            <div class="card-scroll" v-show="showOrder">
                <b-row class="mt-2" v-for="order in orders">
                    <b-col>
                        <b-card @click="orderInfoShow(order)">
                            <b-row class="justify-content-md-center">
                                <b-col>
                                    <h3>{{order.name}} ( {{order.location}} )</h3>
                                </b-col>
                            </b-row>
                            <hr class="mt-2 mb-2">
                            <b-row class="justify-content-md-center">
                                <b-col cols="3">
                                    <p>{{order.year}}</p>
                                    <p class="order-day">{{order.day}}</p>
                                    <p>{{order.month}}</p>
                                </b-col>
                                <b-col cols="5">
                                    <p>{{order.invoice}}</p>
                                    <p>{{order.phone}}</p>
                                    <p>{{order.totalPrice}} Ks</p>
                                </b-col>
                                <b-col cols="4">
                                    <p>-</p>
                                    <p class="text-capitalize">{{order.status}}</p>
                                    <p>-</p>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
            </div>
            <div class="row status-info" v-show="showOrderInfo">
                <div class="col-md-12 ">
                   <table class="table table-cart" >
                        <tr>
                            <th style="width: 100px;">Name</th>
                            <th style="width: 50;">
                                Qty
                            </th>
                            <th>Status</th>
                            <th class="text-right" style="width: 100px;">Total</th>
                        </tr>
                        <tr v-for="(item, index) in orderItems">
                            <td style="width: 100px;">{{item.itemName}}</td>
                            <td style="width: 50;">
                                {{item.quantity}}
                            </td>
                            <td>{{item.status}}</td>
                            <td class="text-right" style="width: 100px;">{{item.price}} Ks</td>
                        </tr>
                        <tr>
                            <td colspan="2">Total Price</td>
                            <td colspan="2" class="text-right">{{totalPrice}} Ks</td>
                        </tr>
                    </table>
                    <form v-on:submit.prevent="onSubmit">
                        <select v-model="statusRequest.status" class="form-control mt-2">
                            <option value="confirmed">Confirmed</option>
                            <option value="shipping">Shipping</option>
                            <option value="completed">Completed</option>
                        </select>
                        <button class="btn btn-success mt-2" style="width: 100%;">Submit</button>
                        <a class="btn btn-success mt-2 text-white" style="width: 100%;"
                            @click="closeOrderInfo()">Close</a>
                    </form>
                </div>
            </div>
            <!-- <router-link class="float-filter" to="">
                <i class="fa fa-filter my-float" style="padding: 37%;bottom: 50px;"></i>
            </router-link> -->
        </b-container>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'item',
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                showOrderInfo: false,
                showOrder: true,
                orders: [],
                orderItems: [],
                totalPrice: '',
                statusRequest: {
                    "status": "",
                    "orderId": ""
                }
            }
        },
        computed: {},
        methods: {
            ...mapActions({
                orderListAction: 'orderListAction',
                statusChangeAction: 'statusChangeAction'
            }),
            async orderList() {
                this.isLoading = true;
                await this.orderListAction().then(res => {
                    if (res.status == "success") {
                        this.orders = res.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
            orderInfoShow(order) {
                console.log(order.item)
                this.showOrder = false
                this.showOrderInfo = true
                this.orderItems = order.item
                this.totalPrice = order.totalPrice
                this.statusRequest.status = order.status
                this.statusRequest.orderId = order.id
            },
            closeOrderInfo() {
                this.showOrder = true
                this.showOrderInfo = false
            },
            async onSubmit() {
                this.isLoading = true
                let option = {
                    orderId: this.statusRequest.orderId,
                    status: this.statusRequest.status,
                }
                await this.statusChangeAction({
                    ...option
                }).then(res => {
                    this.isLoading = false
                    this.statusRequest.orderId = ""
                    this.statusRequest.deliveryId = ""
                    this.statusRequest.status = ""
                    this.orderList()
                    this.closeOrderInfo()
                }).catch(err => this.isLoading = true)
            },
        },
        async mounted() {
            this.orderList()
        }
    }
</script>

<style scoped lang="scss">
    td {
        padding: 5px;
    }
    // .float-filter {
    //     position: fixed;
    //     width: 60px;
    //     height: 60px;
    //     bottom: 90px;
    //     right: 2%;
    //     background-color: #318be3;
    //     color: #FFF;
    //     border-radius: 50px;
    //     text-align: center;
    //     box-shadow: 2px 2px 3px #999;
    // }

    .order-day {
        font-size: larger;
        font-weight: bold;
        font-style: italic;
    }

    .status-info {
        height: 100vh;
        background: #f8f9fa;
        z-index: 9999;
        position: absolute;
        width: 100%;
    }

    .card-scroll {
        margin-bottom: 100px;
        overflow: scroll;
    }
        /* Hide scrollbar for Chrome, Safari and Opera */
    .card-scroll::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .card-scroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    }

</style>